$ = jQuery;

$(function(){
	if (typeof(mapLat) === 'undefined' || typeof(mapLong) === 'undefined') {
		return;
	}

	if ( $(window).width() < 1024) {
		   mapOptions = {
			    zoom: 14,
			    streetViewControl: false,
		            disableDefaultUI:true,
		            draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true,
			    center: new google.maps.LatLng(mapLat,mapLong),
			    mapTypeId: google.maps.MapTypeId.ROADMAP
			  };
	}
	else {
	   	  mapOptions = {
	    	    scrollwheel: false,
		    streetViewControl: false,
	            disableDefaultUI:true,
		    zoom: 14,
		    zoomControl: true,
		    center: new google.maps.LatLng(mapLat,mapLong),
		    mapTypeId: google.maps.MapTypeId.ROADMAP
		  };
	}

	initialize();	
	
	//Set Tabclicks
	$('.mapTabs li a').each(function(){
		$(this).click(function(){
			beachMarker.setPosition(new google.maps.LatLng( $(this).attr('data-lat'), $(this).attr('data-long')));
			responsiveMap.panTo(new google.maps.LatLng( $(this).attr('data-lat'), $(this).attr('data-long')));
		});
	});
	
});



function initialize() {

	 var styles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#052366"
            },
            {
                "saturation": "-70"
            },
            {
                "lightness": "85"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "lightness": "-53"
            },
            {
                "weight": "1.00"
            },
            {
                "gamma": "0.98"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "weight": "4.16"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#a6a6a6"
            },
            {
                "visibility": "off"
            },
            {
                "weight": "1.02"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "saturation": "-18"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8c8c8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#c8c8c8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#57677a"
            },
            {
                "visibility": "on"
            }
        ]
    }
];

	 var styledMap = new google.maps.StyledMapType(styles,{name: "Styled Map"});

    $mapCanvas = $('<div />', { 'id':'map-canvas', 'style':'height:310px;' });
    $mapCanvas.prependTo('.mainText .tab-content .map-canvas');

    //responsiveMap = new google.maps.Map($mapCanvas, mapOptions);


	 responsiveMap = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

	 responsiveMap.mapTypes.set('map_style', styledMap);
   	 responsiveMap.setMapTypeId('map_style');
	 var image = {
                  url: baseUrl + '/assets/images/map-marker.png',
                  scaledSize: new google.maps.Size(53, 59.5),
                };
 	 var myLatLng = new google.maps.LatLng(mapLat,mapLong);
 	 beachMarker = new google.maps.Marker({position: myLatLng,map: responsiveMap,icon: image});
 	 
 	 
 	 //Start centercheck
 	setMapcenter();
 	 
}

var mapTimer;
function setMapcenter(){
	$(window).resize(function() {
		window.clearTimeout(mapTimer);
		mapTimer = window.setTimeout(function(){
			responsiveMap.panTo(beachMarker.getPosition());
		},200);	
	});
}




