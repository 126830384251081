/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        //Give navigation link products an active class
        $('body.single-post .nav-primary #menu-mainmenu > li:first-child').addClass('active'); 

        $('.nav a.dropdown-toggle').each(function(){

            //Clona first-child i submeny (Borde alltid finnas åtminstone en eftersom det är en dropdown)
            $(this).parent().addClass('dropdown');

        });

        $('.hidden-sm .nav a.dropdown-toggle').each(function(){

            //Clona first-child i submeny (Borde alltid finnas åtminstone en eftersom det är en dropdown)
            $(this).parent().addClass('dropdown');

            
            $cloned = $('ul li:first-child',$(this).parent()).clone();
            $('a',$cloned).attr('href',$(this).attr('href')).html($(this).text());


            //Ta klasserna från huvudmenyval men exkludera dropdownklassen
            var $classes = $(this).parent().attr('class').replace('dropdown','');

            //Om det finns en annan submeny som är aktiv, ta bort även active classen som kommer från huvudmenyval då en submeny är active
            $classes = $(this).parent().find('ul li.active').length > 0 ? $classes.replace('active', ''):$classes;

            //Modifiera classer,href och text på clonen för att spegla huvudmenyval
            $cloned.attr('class',$classes).find('a').attr('href',$(this).attr('href')).text($(this).text());

            //Prependa den så den hamnar först i submenyn
            $('ul',$(this).parent()).prepend($cloned);
        });

        $('.langSwitch .currentLan > a').on('click', function(e){
          e.preventDefault();
          $(this).siblings('.dropdown').toggle();
        })

        $('footer .topSearchButton, .submenu-nav .topSearchButton').each(function(){
          $(this).parent().parent().remove();
        })

        $('.banner .navbar .topSearchButton').on('click', function(){
          if($(this).hasClass('fa-search')){
            $class = $(this).attr('class').replace('fa-search', 'fa-times')
          }
          else{
            $class = $(this).attr('class').replace('fa-times', 'fa-search')
          }

          $(this).attr('class', $class);
          $('.topHeader-container .hidden-xs.searchForm').slideToggle('fast');
        })


        $('.navigation .submenu-nav li:first-child').each(function(){
          $original = $('.navbar .hidden-xs .navbar-nav .dropdown.active > a');
          $href = $original.attr('href');
          $title = $original.text();

          $clone = $(this).clone();

          $class = $original.parent().attr('class').replace('dropdown', '');

          $class = $(this).parent().find('li.active').length > 0 ? $class.replace('active', ''):$class;

          $('a', $clone).attr('href', $href).text($title).parent().attr('class', $class);
          $(this).parent().prepend($clone);
          console.log($clone);
        })

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
